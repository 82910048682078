.condensed-panel {
  background-color: var(--primary-color-dark);
  display: grid;
  grid-template-rows: 1fr auto;
  height: 100%;
  opacity: 0;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  padding: calc(var(--header-height-sm) + var(--gap)) var(--gap) var(--gap);
  position: fixed;
  transition: opacity var(--transition-dur);
  visibility: hidden;
  width: 100vw;
  z-index: 888;

  @media (--lg) {
    display: none;
  }

  &--active {
    opacity: 1;
    visibility: visible;
  }
}

/* Condensed Primary Nav */

.condensed-primary-nav {
  margin-bottom: var(--gap-sm);
}

.condensed-primary-nav__link {
  color: #fff;
  display: block;
  font-size: 1.7rem;
  padding: 0.2rem 0;
  text-align: center;
  transition: color var(--transition-dur);

  &:hover,
  &.current,
  &.section {
    color: var(--tertiary-color);
  }
}

/* Condensed Utility Nav */

:root {
  --condensed-utility-text-styles {
    color: #fff;
    font-size: 1.6rem;
    text-transform: uppercase;
  }
}

.condensed-utility-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: var(--gap-xs);
}

.condensed-utility-text {
  @apply (--condensed-utility-text-styles);
  display: block;
  text-align: center;
  width: 100%;
}

.condensed-utility-nav__items {
  display: flex;
  flex-wrap: wrap;
  margin: 0 calc(var(--gap-xs) / 2 * -1);
}

.condensed-utility-nav__item {
  align-items: center;
  display: flex;
  position: relative;

  &:not(:last-child) {
    &::after {
      color: rgba(255, 255, 255, 0.3);
      content: "|";
      font-weight: 300;
      position: absolute;
      right: -0.4rem;
      top: -0.2rem;
    }
  }
}

.condensed-utility-nav__link {
  @apply (--condensed-utility-text-styles);
  padding: 0 calc(var(--gap-xs) / 2);
  transition: color var(--transition-dur);

  &:hover {
    color: var(--tertiary-color);
  }
}

/* Condensed Social Nav */

.condensed-panel__social-nav {
  .social-nav__items {
    justify-content: center;
  }

  .social-nav__link {
    background-color: #fff;
  }

  .social-nav__icon {
    fill: var(--primary-color-dark);
  }
}
