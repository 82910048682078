.gallery-items {
  display: flex;
  flex-wrap: wrap;
  margin: 0 calc(var(--gap-xxs) / 2 * -1);
}

.gallery-item {
  flex: 1 0 50%;
  margin-bottom: var(--gap-xxs);
  max-width: 50%;
  padding: 0 calc(var(--gap-xxs) / 2);

  @media (--lg) {
    flex-basis: calc(100% / 4);
    max-width: calc(100% / 4);
  }
}

.gallery-item__link {
  display: block;
  position: relative;

  &:hover {
    .gallery-item__thumb {
      border-color: var(--primary-color);
    }
  }
}

.gallery-item__thumb {
  border: 0.8rem solid #fff;
  border-radius: var(--border-radius);
  transition: border-color var(--transition-dur);
}

.gallery-item__icon {
  @apply (--grunge-circle-mask);
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
  bottom: var(--gap-sm);
  display: flex;
  height: 5rem;
  justify-content: center;
  position: absolute;
  right: var(--gap-sm);
  width: 5rem;

  svg {
    fill: var(--dark-color);
    height: 50%;
    width: 50%;
  }
}

.gallery-item__count {
  display: none;
}
