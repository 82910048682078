/* Structure */

.blog-wrap {
  @apply (--white-to-yellow-gradient-bg);
  min-height: 40rem;
  padding: var(--gap-xl) 0;

  @media (--md) {
    padding: var(--gap-xl) 0 0 0;
  }
}

/* .blog-page__filter-header {
  margin-bottom: var(--gap-xl);
} */
