:root {
  --input-height: 4.6rem;

  --input-styles: {
    appearance: none;
    background-color: transparent;
    border-bottom: 0.1rem solid var(--neutral-color-darker);
    border-left: 0;
    border-radius: 0;
    border-right: 0;
    border-top: 0;
    box-shadow: none;
    color: var(--text-color);
    font-family: var(--primary-font);
    font-size: 1.6rem;
    height: var(--input-height);
    line-height: 1.3;
    margin: 0;
    outline: none;
    padding: 1rem 0;
  }

  --input-focus-styles {
    border-bottom: 0.1rem solid var(--tertiary-color);
    box-shadow: none;
    outline: 0 none;
  }

  --label-styles {
    color: var(--text-color-dark);
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.3;
    margin: 0 !important;
    padding: 0 0 var(--gap-xxs) 0;
  }

  --alert-box-styles {
    border-radius: var(--border-radius);
    color: #fff;
    padding: var(--gap-sm);
    text-align: center;

    strong {
      color: #fff;
    }
  }

  --success-box-styles: {
    background-color: var(--success-color-dark);
    border: 0.4rem solid var(--success-color-darkest);
  }

  --error-box-styles: {
    background-color: var(--alert-color-dark);
    border: 0.4rem solid var(--alert-color-darkest);
  }
}

form {
  display: block;
  margin: 0 0 var(--gap) 0;

  label {
    &.left {
      @apply --label-styles;

      small {
        font-size: 1.4rem;
        font-weight: 400;
      }
    }

    &.right {
      font-size: 1.6rem;
    }
  }

  legend {
    &.left {
      @apply --label-styles;
    }
  }

  ul,
  ol {
    font-size: 1.6rem;
    margin: 0;
    padding: 0 0 var(--gap-sm) var(--gap);

    li {
      margin-bottom: 0.5rem;

      &:last-child {
        margin-bottom: 0;
      }

      &.even {
        background-color: none;
      }
    }
  }

  h2,
  .FormHeading {
    border-bottom: 0.1rem solid var(--neutral-color);
    border-top: 0.1rem solid var(--neutral-color);
    font-size: 2.2rem;
    line-height: 1.3;
    margin: var(--gap-xs) 0 var(--gap-sm);
    padding: var(--gap-xs) 0;
    width: 100%;
  }

  .dropzone {
    background: none;
    border: 0;
    padding: 0 2rem;
  }

  .dz-message {
    display: none;
  }
}

fieldset {
  border: 0;
  margin-bottom: var(--gap-xl);
}

.fields {
  display: grid;
  grid-gap: var(--gap) 0;
  grid-template-columns: 1fr;

  @media (--md) {
    grid-gap: var(--gap-lg) var(--gap);
    grid-template-columns: repeat(4, 1fr);
  }
}

.step-navigation {
  .step-buttons {
    padding: 0;
  }
}

.clear {
  display: none;
}

input {
  @apply --input-styles;
  width: 100%;

  &::-webkit-input-placeholder {
    color: var(--text-color-lighter);
  }

  &::-moz-placeholder {
    color: var(--text-color-lighter);
  }

  &:-ms-input-placeholder {
    color: var(--text-color-lighter);
  }

  &:-moz-placeholder {
    color: var(--text-color-lighter);
  }

  &:focus,
  &:active,
  &:target {
    @apply --input-focus-styles;
  }

  &.checkbox {
    align-items: center;
    appearance: none;
    background-color: #fff;
    border: 0.1rem solid var(--text-color);
    display: flex;
    height: 2.4rem;
    justify-content: center;
    margin-right: var(--gap-xxs);
    min-height: 2.4rem;
    min-width: 2.4rem;
    padding: 0;
    width: 2.4rem;

    &:checked {
      &::after {
        color: var(--text-color);
        content: "✓";
        font-size: 2.1rem;
      }
    }
  }
}

textarea {
  @apply --input-styles;
  min-height: 14rem;
  width: 100%;

  &:focus {
    @apply --input-focus-styles;
  }

  &::-webkit-input-placeholder {
    color: var(--text-color-lighter);
  }

  &::-moz-placeholder {
    color: var(--text-color-lighter);
  }

  &:-ms-input-placeholder {
    color: var(--text-color-lighter);
  }

  &:-moz-placeholder {
    color: var(--text-color-lighter);
  }
}

select {
  appearance: none;
  background: transparent;
  background-image: url("../img/angle-down.png");
  background-position: right 0 center;
  background-repeat: no-repeat;
  background-size: 2.1rem 1.1rem;
  border-bottom: 0.1rem solid var(--neutral-color-darker);
  border-left: 0;
  border-radius: 0;
  border-right: 0;
  border-top: 0;
  color: var(--text-color);
  font-family: var(--primary-font);
  font-size: 1.9rem;
  height: var(--input-height);
  line-height: 1.3;
  padding-right: var(--gap);
  width: 100%;

  &[multiple] {
    height: auto;
  }

  &.select--subtle {
    color: var(--text-color-lighter);
    font-size: 1.5rem;
  }
}

.field {
  grid-column: span 2;
  position: relative;

  &.textarea,
  &.dropdown,
  &.checkbox,
  &.uploadfield,
  &.dropzonefield {
    grid-column: 1 / -1;
  }

  &.checkbox {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  &.text,
  &.label {
    label {
      color: var(--text-color-lighter);
      left: 0;
      position: absolute;
      top: 1.2rem;
      transition: all var(--transition-dur);
      z-index: 10;
    }
  }

  &.dropdown {
    label {
      &.left {
        display: none;
      }
    }
  }

  &.uploadfield,
  &.dropzonefield {
    background-image: url("../img/upload-icon.png");
    background-position: right var(--gap-xs) top var(--gap-xs);
    background-repeat: no-repeat;
    background-size: 2.4rem 2.4rem;
    border: 0.2rem dashed var(--neutral-color);
    cursor: pointer;
    margin-bottom: var(--gap);
    position: relative;
    transition: border-color var(--transition-dur);

    &:hover {
      border-color: var(--tertiary-color);
    }

    label {
      &.left {
        color: var(--text-color-dark);
        display: block;
        font-size: 1.9rem;
        margin: 0;
        padding: var(--gap-xs) var(--gap);
      }
    }

    input {
      border-bottom: 0;
      padding: 0 var(--gap) var(--gap-xs);
    }

    .description {
      color: var(--text-color-lighter);
      font-size: 1.4rem;
      padding: var(--gap-xs) 0 0 var(--gap);
      position: absolute;
    }

    .parsley-required {
      left: 50%;
      margin-top: 1.5rem;
      position: absolute;
      transform: translateX(-50%);
    }
  }

  &--focused {
    &.text,
    &.dropdown,
    &.textarea {
      label {
        color: var(--primary-color);
        font-size: calc(1.1rem + 0.1vw);
        font-weight: 700;
        top: calc(var(--gap-xxs) * -1);
      }
    }
  }

  &--disabled {
    color: var(--text-color-lighter);
    cursor: not-allowed;
    opacity: 0.5;
  }

  &.field--full {
    grid-column: 1 / -1;
  }

  &.field--half {
    grid-column: 1 / -1;

    @media (--md) {
      grid-column: span 2;
    }
  }

  &.field--quarter {
    grid-column: 1 / -1;

    @media (--md) {
      grid-column: span 1;
    }
  }

  &.nocaptcha {
    grid-column: 1/-1;
    display: flex;
    justify-content: center;

    padding-top: 2rem;
  }
}

ul {
  &.optionset,
  &.checkboxset {
    display: block;
    margin: 0;
    padding: 0 0 var(--gap-sm) 0;
    width: 100%;

    li {
      align-items: center;
      display: flex;
      list-style: none;
    }

    input {
      margin: 0 0.8rem;
      padding: 0;
    }
  }

  &.checkboxset {
    input {
      appearance: none;
    }
  }

  &.optionset {
    margin-left: 0 !important;

    input {
      appearance: radio;
      height: auto;
      width: auto;
    }
  }
}

.middleColumn {
  clear: left;
  position: relative;
  width: auto;
}

.editable-area {
  .checkboxsetfield,
  .optionset {
    margin: 0;
    padding: 0;
  }
}

label {
  &.right {
    margin: 0;
    padding: 0;
    text-align: left;
  }
}

.error-message {
  color: var(--alert-color-dark);
  display: block;
  font-size: 1.6rem;
  font-weight: 700;
  margin-top: 1rem;
}

.error-messages {
  @apply --alert-box-styles;
  @apply --error-box-styles;
  margin: 0;
  opacity: 0;
  padding: 0;
  transition: opacity var(--transition-dur);

  li {
    padding-left: var(--gap-sm);
    padding-right: var(--gap-sm);

    &:first-child {
      padding-top: var(--gap-sm);
    }

    &:last-child {
      padding-bottom: var(--gap-sm);
    }
  }

  &--active {
    margin-bottom: var(--gap);
    opacity: 1;
  }
}

.message {
  &.bad,
  &.error {
    color: var(--alert-color);
    display: block;
    font-weight: 700;
    margin: var(--gap-xxs) 0 0;
  }
}

.success {
  @apply --alert-box-styles;
  @apply --success-box-styles;
  display: block;
  margin: 0 0 var(--gap-sm) 0;
}

.form-msg {
  display: none;
  font-weight: 700;
  margin: -1.5rem 0 var(--gap);
  text-align: center;

  &--hide {
    display: none;
  }

  &--active {
    display: block;
  }
}

.form-text {
  color: var(--text-color);
}

.btn-toolbar {
  display: flex;
  justify-content: center;
}

.action {
  @apply (--btn-styles);
  @apply (--tertiary-btn-styles);
  height: auto;
  min-width: 26rem;
  width: auto;

  &--disabled {
    cursor: not-allowed;
    opacity: 0.5;

    &:hover {
      background-color: var(--tertiary-color);
    }
  }
}

.parsley-required,
.parsley-type {
  color: var(--alert-color);
  display: block;
  font-weight: 700;
  margin-top: var(--gap-xxs);
  text-align: center;
}

.nocaptcha {
  display: flex;
  justify-content: center;

  padding-top: 2rem;
}
