:root {
  --pagination-gap: 6px;
}

.pagination {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.pagination__items {
  align-items: center;
  display: flex;
  flex: 1 0 auto;
  justify-content: center;
}

.pagination__item {
  &--active {
    .pagination__link {
      color: var(--text-color-dark);
      font-weight: 700;

      &::after {
        background-color: var(--tertiary-color);
        bottom: 0;
        content: "";
        height: 0.2rem;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        width: 70%;
      }
    }
  }
}

.pagination__link,
.pagination__direction-nav__link {
  display: block;
  font-family: var(--primary-font);
  font-size: calc(1.4rem + 0.1vw);
  letter-spacing: 0.1rem;
  padding: 0.4rem 0.6rem;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  transition-duration: color var(--transition-dur);

  &:hover {
    color: var(--primary-color);
  }
}

.pagination__link {
  color: var(--text-color);
}

.pagination__direction-nav__link {
  color: var(--text-color-dark);
  font-weight: 700;

  &--prev {
    margin-right: var(--gap-xxs);
  }

  &--next {
    margin-left: var(--gap-xxs);
  }
}
