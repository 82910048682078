.split-page-header {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: var(--gap);

  @media (--md) {
    margin-bottom: var(--gap-xl);
  }

  > * {
    flex: 1 0 100%;

    @media (--md) {
      flex-basis: 50%;
    }

    &:last-child {
      display: flex;
      flex-wrap: wrap;

      @media (--md) {
        justify-content: flex-end;
      }
    }

    &:not(:last-child) {
      margin-bottom: var(--gap-xs);

      @media (--md) {
        margin-bottom: 0;
      }
    }
  }
}

.split-page-header__title {
  align-items: center;
  color: #fff;
  display: flex;
  font-size: calc(3.6rem + 0.15vw);

  span {
    font-size: calc(1.4rem + 0.15vw);
  }
}
