.primary-bar {
  display: none;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (--lg) {
    display: flex;
  }
}

.primary-nav__items {
  display: flex;
  flex-wrap: wrap;
  margin: 0 calc(var(--gap-xs) / 2 * -1);

  @media (--xl) {
    margin: 0 calc(var(--gap) / 2 * -1);
  }
}

.primary-nav__link {
  border-radius: var(--border-radius);
  color: var(--primary-color-dark);
  display: block;
  font-size: 1.7rem;
  font-weight: 700;
  padding: 0.5rem calc(var(--gap-xs) / 2);
  text-transform: uppercase;
  transition: color var(--transition-dur);

  @media (--xl) {
    padding: 0.5rem calc(var(--gap) / 2);
  }

  &.current,
  &.section {
    @apply (--grunge-rectangle-mask);
    background-color: var(--secondary-color);
    color: #fff;
  }
}
