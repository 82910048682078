.social-nav__items {
  display: flex;
  flex-wrap: wrap;
  margin: 0 calc(var(--gap-xxs) / 4 * -1);
}

.social-nav__item {
  padding: 0 calc(var(--gap-xxs) / 4);
}

.social-nav__link {
  align-items: center;
  background-color: var(--primary-color-dark);
  border-radius: 50%;
  display: flex;
  height: var(--social-nav-icon-size);
  justify-content: center;
  mask-image: url("../icons/grunge-circle-mask.svg");
  mask-repeat: no-repeat;
  mask-size: contain;
  transition: background-color var(--transition-dur);
  width: var(--social-nav-icon-size);

  &:hover {
    background-color: var(--primary-color);
  }
}

.social-nav__icon {
  fill: #fff;
  height: 50%;
  width: 50%;
}
