.clear-filters {
  display: flex;

  &--center {
    justify-content: center;
  }
}

.clear-filters__btn {
  align-items: center;
  color: var(--text-color-light);
  display: none;
  font-family: var(--primary-font);
  font-size: calc(1.3rem + 0.1vw);
  font-weight: 600;
  letter-spacing: 0.1rem;
  line-height: 1;
  margin: 0 0 var(--gap) 0;
  padding: 0;
  text-align: center;
  text-transform: uppercase;

  &--show {
    display: flex;
  }

  &:hover {
    color: var(--primary-color);
  }
}

.clear-filters__icon {
  align-items: center;
  display: flex;
  height: 1.8rem;
  justify-content: center;
  margin-right: var(--gap-xxs);
  width: 1.8rem;

  svg {
    fill: var(--text-color-light);
    height: 100%;
    width: 100%;
  }
}
