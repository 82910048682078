:root {
  --utility-text-styles: {
    color: var(--secondary-color);
    font-size: 1.4rem;
    font-weight: 700;
    text-transform: uppercase;
  }

  --social-nav-icon-size: 3.2rem;
}

.utility-bar {
  display: none;
  justify-content: space-between;

  @media (--lg) {
    display: flex;
  }
}

.utility-bar__col--2 {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.utility-text {
  @apply (--utility-text-styles);
}

/* Utility Nav */

.utility-nav {
  margin-right: var(--gap-sm);
}

.utility-nav__items {
  display: flex;
  flex-wrap: wrap;
  margin: 0 calc(var(--gap-xs) / 2 * -1);
}

.utility-nav__item {
  align-items: center;
  display: flex;
  position: relative;

  &:not(:last-child) {
    &::after {
      color: var(--text-color-lighter);
      content: "|";
      font-weight: 300;
      position: absolute;
      right: -0.4rem;
      top: -0.3rem;
    }
  }
}

.utility-nav__link {
  @apply (--utility-text-styles);
  display: block;
  padding: 0 calc(var(--gap-xs) / 2);
  transition: color var(--transition-dur);

  &:hover {
    color: var(--primary-color);
  }
}
