.homepage-overlay-modal {
  width: 100%;
  padding: 20px;
}

.modal--content .homepage-overlay-modal.is-image,
.modal--content .homepage-overlay-modal.is-content {
  max-width: 1160px;
}

.modal--content .homepage-overlay-modal.only-video {
  padding: 0;
}

.modal--content .homepage-overlay-modal.has-video {
  max-width: 1080px;
}

.homepage-overlay__image {
  display: block;
}

.homepage-overlay__video {
  position: relative;
  width: 100%;
}

.homepage-overlay__video:after {
  content: "";
  display: block;
  height: 0;
  width: 100%;
  padding-top: 56.25%;
}

.homepage-overlay__video iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.homepage-overlay__content {
  background: #fff;
  color: #000;
  padding: 1.25rem;
  border-radius: 5px;
}

.homepage-overlay__video + .homepage-overlay__content {
  margin-top: 20px;
}

.homepage-overlay__ctas {
  margin-top: 30px;
}

.homepage-overlay__ctas li {
  padding-bottom: 15px;
}

.homepage-overlay__ctas .btn {
  padding: 10px 20px;
}
