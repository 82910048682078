.login-page {
  align-items: center;
  display: flex;
  justify-content: center;
}

.login-form {
  max-width: var(--max-width-xs);
  padding: var(--gap);
  width: 100%;

  @media (--md) {
    padding: var(--gap-xl);
  }

  form {
    margin-bottom: 0;
  }

  fieldset {
    margin-bottom: var(--gap);
  }

  .fields {
    grid-gap: var(--gap);
  }

  .btn-toolbar {
    align-items: center;
    justify-content: flex-start;
  }
}

.login-form__title {
  font-size: calc(2.4rem + 0.1vw);
  margin-bottom: var(--gap);
  position: relative;
  text-align: center;
}

#MemberLoginForm_LoginForm_action_doLogin {
  margin: 0 var(--gap-xs) 0 0;
}

#ForgotPassword {
  margin-bottom: 0;
  text-align: center;

  &::before {
    color: var(--text-color-lighter);
    content: "or ";
  }
}
