:root {
  --card-thumb-height: 25rem;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  margin: 0 calc(var(--gap) / 2 * -1);
}

.card {
  flex: 1 1 100%;
  margin-bottom: var(--gap-lg);
  max-width: 100%;
  padding: 0 calc(var(--gap) / 2);

  @media (--md) {
    flex-basis: calc(100% / 2);
    max-width: calc(100% / 2);
  }

  @media (--xl) {
    flex-basis: calc(100% / 3);
    max-width: calc(100% / 3);
  }
}

.card__inner {
  background-color: #fff;
  border: 0.1rem solid var(--neutral-color-dark);
  height: 100%;
}

.card__link {
  display: block;
  height: 100%;
  transition: border-color var(--transition-dur);

  &:hover {
    border-color: var(--primary-color);

    .card__title {
      color: var(--primary-color);
    }

    .card__go {
      svg {
        right: 2.2rem;
      }
    }
  }
}

.card__thumb {
  background-color: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom: 0.1rem solid var(--neutral-color);
  height: var(--card-thumb-height);
  position: relative;
  width: 100%;
}

.card__thumb__cat {
  color: var(--secondary-color);
  font-family: var(--secondary-font);
  font-size: 2.8rem;
  left: 50%;
  line-height: 1.1;
  margin: 4rem 2rem 0 0;
  position: absolute;
  text-transform: uppercase;
  top: 50%;
  transform: translate(-75%, -35%);

  span {
    display: block;
    font-size: 1.8rem;
  }
}

.card__go {
  align-items: center;
  bottom: var(--gap-xs);
  display: flex;
  height: 4.2rem;
  justify-content: center;
  position: absolute;
  width: 100%;

  &::before {
    @apply (--grunge-circle-mask);
    background-color: var(--tertiary-color);
    border-radius: 50%;
    content: "";
    height: 4.2rem;
    position: absolute;
    right: var(--gap-xs);
    width: 4.2rem;
  }

  svg {
    fill: #fff;
    height: 3.2rem;
    position: absolute;
    right: 3.2rem;
    transition: right var(--transition-dur);
    width: 3.2rem;
  }
}

.card__site-cred {
  left: var(--gap-xs);
  position: absolute;
  top: var(--gap-xs);
}

.card__details {
  display: flex;
  flex-direction: column;
  height: calc(100% - var(--card-thumb-height));
  padding: var(--gap-sm);

  > * {
    &:first-child {
      flex: 1 0 auto;
    }
  }
}

.card__title {
  font-size: 2.1rem;
  margin-bottom: 0;
  transition: color var(--transition-dur);
}

.card__category {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.card__category__icon {
  align-items: center;
  display: flex;
  height: 1.6rem;
  justify-content: center;
  margin-right: var(--gap-xxs);
  width: 1.6rem;

  svg {
    fill: var(--text-color);
    height: 100%;
    width: 100%;
  }
}

.card__category__title {
  color: var(--text-color);
  font-size: 1.5rem;
  text-transform: uppercase;
}

.card__details__footer {
  margin-top: var(--gap-sm);
}
