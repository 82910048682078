:root {
  --story-intro-year-size: 15rem;
  --story-intro-year-size-sm: 10rem;
  --story-intro-dot-size: 1rem;
}

/* Opening */

.opening {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 calc(var(--gap-xl) / 2 * -1);

  > * {
    flex: 1 0 100%;
    padding: 0 calc(var(--gap-xl) / 2);

    @media (--lg) {
      flex-basis: 50%;
      max-width: 50%;
    }
  }
}

.opening__year {
  color: var(--text-color-darker);
  font-family: var(--secondary-font);
  font-size: 10rem;
  line-height: 1;

  @media (--lg) {
    text-align: right;
  }
}

/* Typography */

.story-title {
  color: var(--text-color-darker);
  font-family: var(--primary-font);
  font-size: 2.1rem;
  font-weight: 600;
  margin-bottom: var(--gap-xs);
  padding-bottom: var(--gap-xs);
  position: relative;
  text-transform: inherit;

  &::after {
    @apply (--grunge-rectangle-mask);
    background-color: var(--text-color-darker);
    bottom: 0;
    content: "";
    height: 0.4rem;
    left: 0;
    position: absolute;
    width: 12rem;
  }
}

.story-content {
  font-size: 1.6rem;
}

/* Story */

.story-cover {
  margin-bottom: var(--gap);
  position: relative;
}

.story-year {
  @apply (--grunge-circle-mask);
  align-items: center;
  background-color: var(--secondary-color);
  border-radius: 50%;
  display: flex;
  height: var(--story-intro-year-size-sm);
  justify-content: center;
  position: absolute;
  top: calc(var(--story-intro-year-size-sm) / 2 * -1);
  width: var(--story-intro-year-size-sm);

  @media (--lg) {
    height: var(--story-intro-year-size);
    top: calc(var(--story-intro-year-size) / 2 * -1);
    width: var(--story-intro-year-size);
  }

  span {
    color: #fff;
    font-family: var(--secondary-font);
    font-size: 2.6rem;

    @media (--lg) {
      font-size: 4.2rem;
    }
  }

  &--sm {
    height: var(--story-intro-year-size-sm);
    top: calc(var(--story-intro-year-size-sm) / 2 * -1);
    width: var(--story-intro-year-size-sm);

    span {
      font-size: 2.6rem;
    }
  }
}

/* Story Intros */

.story-intros {
  display: flex;
  flex-wrap: wrap;
  margin: 0 calc(var(--gap-xxxl) * -1);
  padding-top: var(--gap);
  position: relative;

  @media (--lg) {
    padding-top: 0;
  }

  &::after {
    background-color: var(--dark-color);
    content: "";
    display: none;
    height: 100%;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    width: 0.1rem;

    @media (--lg) {
      display: block;
    }
  }

  > * {
    flex: 1 0 100%;
    padding: 0 var(--gap-xxxl);

    @media (--lg) {
      flex-basis: 50%;
      max-width: 50%;
    }
  }
}

.story-intro-col {
  &--left {
    @media (--lg) {
      padding-top: var(--gap-xxxl);
    }

    .story-intro {
      &::before {
        right: calc(var(--gap-xxxl) * -1);
      }

      &::after {
        right: calc(var(--gap-xxxl) * -1);
        transform: translateX(50%);
      }
    }

    .story-intro__year {
      left: calc(var(--story-intro-year-size-sm) / 4 * -1);

      @media (--lg) {
        left: inherit;
        right: calc(var(--story-intro-year-size) / 2 * -1);
      }
    }
  }

  &--right {
    @media (--lg) {
      padding-top: calc(var(--gap-xxxl) * 2.5);
    }

    .story-intro {
      &::before {
        left: calc(var(--gap-xxxl) * -1);
      }

      &::after {
        left: calc(var(--gap-xxxl) * -1);
        transform: translateX(-50%);
      }
    }

    .story-intro__year {
      left: calc(var(--story-intro-year-size-sm) / 4 * -1);

      @media (--lg) {
        left: calc(var(--story-intro-year-size) / 2 * -1);
      }
    }
  }
}

.story-intro {
  padding-bottom: var(--gap-xxl);
  position: relative;

  @media (--lg) {
    padding-bottom: var(--gap-xxxl);
  }

  &::before {
    background-color: var(--dark-color);
    content: "";
    height: 0.1rem;
    position: absolute;
    top: 0;
    width: calc(var(--gap-xxxl) - var(--story-intro-year-size) / 2);
  }

  &::after {
    background-color: var(--dark-color);
    border-radius: 50%;
    content: "";
    height: var(--story-intro-dot-size);
    position: absolute;
    top: calc(var(--story-intro-dot-size) / 2 * -1);
    width: var(--story-intro-dot-size);
  }
}

.story-intro__details {
  > * {
    &:not(:last-child) {
      margin-bottom: var(--gap-sm);
    }
  }
}

/* Story Full */

.story-full {
  display: flex;
  flex-wrap: wrap;
  margin: 0 calc(var(--gap-xl) / 2 * -1);
  position: relative;

  > * {
    flex: 1 0 100%;
    padding: 0 calc(var(--gap-xl) / 2);

    @media (--lg) {
      flex-basis: 50%;
      max-width: 50%;
    }
  }
}

.story-full__year {
  left: calc(var(--gap-sm) * -1);
  top: calc(var(--gap-sm) * -1);

  @include (--lg) {
    left: calc(var(--story-intro-year-size-sm) / 2 * -1);
    top: calc(var(--story-intro-year-size) / 2 * -1);
  }
}

.story-full__cover {
  margin-bottom: var(--gap);

  @media (--lg) {
    margin-bottom: 0;
  }
}
