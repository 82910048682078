.template-content {
  /* Typography */

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 0 var(--gap-sm);
  }

  strong {
    color: var(--text-color-darker);
  }

  /* List Styles */

  ul,
  ol {
    margin: 0 0 var(--gap) var(--gap-lg);
    padding: 0;

    > li {
      list-style-position: outside;
      margin-bottom: 0.5rem;

      > ul,
      > ol {
        margin-top: 1rem;
      }
    }
  }

  ul {
    > li {
      list-style: disc;
    }
  }

  ol > {
    li {
      list-style: decimal;
    }
  }

  /* Table Styles */

  table {
    border: 0;
    border-collapse: collapse;
    margin: 0 0 var(--gap);
    width: 100%;

    th {
      background-color: var(--primary-color);
      border-bottom: 0.1rem solid var(--primary-color-dark);
      border-right: 0.1rem solid var(--primary-color-dark);
      color: #fff;
      font-family: var(--secondary-font);
      font-weight: 700;
      padding: 1rem 1.5rem;
      text-align: left;

      a {
        color: #fff;

        &:hover {
          color: var(--primary-color-lightest);
          text-decoration: underline;
        }
      }

      small {
        color: var(--primary-color-lightest);
      }
    }

    tr {
      td {
        border-bottom: 0.1rem solid var(--neutral-color);
        color: var(--text-color);
        padding: 1rem 1.5rem;
        vertical-align: top;
      }

      &:nth-child(even) {
        background-color: var(--neutral-color-lightest);
      }

      &:nth-child(odd) {
        background-color: #fff;
      }

      &:last-child {
        border-bottom: 0;
      }
    }

    ul {
      margin: 0.2rem 0;
      padding: 0;

      li {
        list-style-type: none;
        margin: 0 0 0.3rem;
        padding: 0;
      }
    }

    tfoot {
      td {
        background-color: var(--dark-color);
        color: #fff;
      }
    }
  }

  /* Quote Styles */

  blockquote {
    border: 0;
    color: var(--text-color);
    font-family: var(--secondary-font);
    font-size: calc(2.1rem + 0.1vw);
    font-style: italic;
    font-weight: 400;
    line-height: 1.5;
    margin: 0 0 var(--gap-sm);
    padding: 0.5rem var(--gap) 0;
    position: relative;
    text-align: center;
    width: 100%;
    z-index: 2;

    p {
      display: inline-block;

      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }

  q {
    font-family: var(--secondary-font);
    font-style: italic;
    font-weight: 400;
  }

  figcaption,
  cite {
    color: var(--text-color-light);
    display: block;
    font-size: calc(1.3rem + 0.1vw);
    font-style: normal;
    font-weight: 300;
    line-height: 1.5;
    margin: 0 0 var(--gap) 0;
    text-align: right;

    &::before {
      content: "\2014\00a0";
      display: inline-block;
    }
  }
}
