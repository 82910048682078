:root {
  --btn-styles: {
    appearance: none;
    border: 0.2rem solid transparent;
    border-radius: 4rem;
    color: var(--text-color);
    cursor: pointer;
    display: inline-block;
    font-family: var(--primary-font);
    font-size: calc(1.3rem + 0.1vw);
    font-weight: 600;
    letter-spacing: 0.1rem;
    line-height: 1;
    margin-bottom: 0;
    padding: 2rem 6rem;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    transition: background-color var(--transition-dur),
      color var(--transition-dur);
    vertical-align: middle;
    white-space: nowrap;
  }

  --primary-btn-styles: {
    background-color: var(--primary-color);
    color: #fff;

    &:hover {
      background-color: var(--primary-color-dark);
      color: #fff;
    }
  }

  --tertiary-btn-styles: {
    background-color: var(--tertiary-color);
    color: #fff;

    &:hover {
      background-color: var(--primary-color);
      color: #fff;
    }
  }
}

.btn {
  @apply (--btn-styles);

  &--primary {
    @apply (--primary-btn-styles);
  }

  &--secondary {
    background-color: var(--secondary-color);
    color: #fff;

    &:hover {
      background-color: var(--secondary-color-dark);
      color: #fff;
    }
  }

  &--tertiary {
    @apply (--tertiary-btn-styles);
  }

  &--white {
    background-color: #fff;
    color: var(--tertiary-color);

    &:hover {
      background-color: var(--primary-color-dark);
      color: #fff;
    }
  }

  &--offset {
    background-color: var(--neutral-color-lighter);
    color: var(--text-color);
    font-weight: 400;

    &:hover {
      background-color: var(--neutral-color);
      color: var(--text-color);
    }
  }

  &--sm {
    font-size: calc(1.1rem + 0.1vw);
    padding: 1rem 1.8rem;
  }

  &--full {
    display: block;
    width: 100%;
  }

  &--short {
    padding-bottom: 2rem;
    padding-top: 2rem;
  }

  &--inline-on-md {
    @media (--md) {
      display: inline-block;
      width: auto;
    }
  }

  &--full-on-mobile {
    width: 100%;

    @media (--md) {
      width: auto;
    }
  }

  &--ghost {
    background-color: transparent;
    border-color: var(--dark-color);
    color: var(--dark-color);

    &:hover {
      background-color: var(--dark-color);
      border-color: transparent;
      color: #fff;
    }
  }

  &--ghost-reversed {
    background-color: transparent;
    border-color: #fff;
    color: #fff;

    &:hover {
      background-color: var(--primary-color-dark);
      border-color: transparent;
      color: #fff;
    }
  }

  &--disabled {
    pointer-events: none;

    &:hover {
      color: inherit;
    }
  }
}

.btn--with-icon {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 1.6rem 6rem;
}

.btn__icon {
  fill: #fff;
  height: 2.1rem;
  left: var(--gap-sm);
  margin-right: var(--gap-xxs);
  width: 2.1rem;

  &--lg {
    height: 2.8rem;
    width: 2.8rem;
  }
}

/* Buttons Group */

.btns-group {
  display: flex;
  flex-wrap: wrap;
  margin: 0 calc(var(--gap-xxs) / 2 * -1);

  &--centered {
    justify-content: center;
  }

  &--full-on-mobile {
    > * {
      width: 100%;

      @media (--md) {
        width: inherit;
      }

      &:not(:last-child) {
        margin-bottom: var(--gap-xs);

        @media (--md) {
          margin-bottom: inherit;
        }
      }
    }

    .btn {
      width: 100%;

      @media (--md) {
        width: inherit;
      }
    }
  }

  > * {
    padding: 0 calc(var(--gap-xxs) / 2);
  }
}

.btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
