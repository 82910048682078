.subscribe-modal {
  @media (--xl) {
    background-image: url("../img/gradient-fish.png");
    background-position: var(--gap-xl) var(--gap-xl);
    background-repeat: no-repeat;
    background-size: 40rem 40.8rem;
  }
}

.subscribe-modal__content {
  @media (--xl) {
    padding-left: 40%;
  }
}

.subscribe-modal__subscribe-form {
  .form-msg {
    margin: var(--gap) 0 0;
    text-align: left;
  }

  form {
    margin-bottom: 0;
  }

  .fields {
    grid-template-columns: 1fr;
  }

  fieldset {
    margin-bottom: var(--gap);
  }

  .btn-toolbar {
    justify-content: flex-start;
  }
}
