:root {
  --mfp-arrow-size: 10rem;
  --mfp-arrow-size-sm: 5rem;
  --mfp-border-width: 0.7rem;
  --mfp-close-size: 7.5rem;
  --mfp-close-size-sm: 5rem;
}

.mfp-zoom-out-cur {
  .mfp-gallery {
    img {
      &.mfp-img {
        padding: 0;
      }
    }

    .mfp-figure,
    img.mfp-img {
      max-height: calc(100vh - var(--mfp-border-width) * 2) !important;
      max-width: calc(100vw - var(--mfp-border-width) * 2) !important;

      @media (--lg) {
        max-height: 80vh !important;
        max-width: calc(100vw - var(--mfp-arrow-size) * 4) !important;
      }
    }

    .mfp-img {
      border: var(--mfp-border-width) solid #fff;
    }

    .mfp-bottom-bar {
      left: inherit;
      margin-top: inherit;
      position: inherit;
      top: inherit;
    }

    .mfp-counter {
      background-color: #fff;
      color: var(--text-color);
      font-size: 1.7rem;
      left: 0;
      padding: var(--gap-xs) var(--gap);
      right: inherit;
      top: 0;
    }

    .mfp-title {
      background-color: #fff;
      bottom: 0;
      color: var(--text-color);
      font-size: 1.6rem;
      padding: var(--gap);
      position: absolute;
      width: 100%;

      &:empty {
        display: none;
      }
    }

    .mfp-arrow {
      @apply (--grunge-circle-mask);
      align-items: center;
      background-color: #fff;
      border-radius: 50%;
      display: flex;
      height: var(--mfp-arrow-size-sm);
      justify-content: center;
      opacity: 1;
      transition: background-color var(--transition-dur);
      width: var(--mfp-arrow-size-sm);

      &--left {
        left: var(--gap);
        transform: rotate(0);
      }

      &--right {
        right: var(--gap);
        transform: rotate(180deg);
      }

      @media (--lg) {
        height: var(--mfp-arrow-size);
        width: var(--mfp-arrow-size);
      }

      &:hover {
        background-color: var(--tertiary-color);
      }

      &::after,
      &::before {
        display: none;
      }

      svg {
        fill: var(--dark-color);
        height: 50%;
        pointer-events: none;
        width: 50%;
      }
    }

    .mfp-close {
      align-items: center;
      background-color: var(--tertiary-color);
      border-radius: 0;
      cursor: pointer;
      display: flex;
      height: var(--mfp-close-size-sm);
      justify-content: center;
      opacity: 1;
      padding: 0;
      position: absolute;
      right: 0;
      top: 0;
      transition: background-color var(--transition-dur);
      width: var(--mfp-close-size-sm);
      z-index: 999;

      @media (--lg) {
        border-radius: 50%;
        height: var(--mfp-close-size);
        right: calc(var(--mfp-close-size) / 2 * -1);
        top: calc(var(--mfp-close-size) / 2 * -1);
        width: var(--mfp-close-size);
      }

      &:hover {
        background-color: var(--primary-color);
      }

      svg {
        fill: #fff;
        height: 40%;
        pointer-events: none;
        width: 40%;
      }
    }
  }
}
