:root {
  --hamburger-offset: 8px;
  --hamburger-width: 25px;

  --hamburger-line {
    background-color: var(--primary-color-dark);
    display: block;
    height: 3px;
    position: relative;
    transition: all var(--transition-dur);
    width: var(--hamburger-width);
  }
}

.hamburger {
  appearance: none;
  background: transparent;
  border: 0;
  cursor: pointer;
  display: block;
  height: 19px;
  outline: none;
  padding: 0;
  position: absolute;
  right: var(--gap);
  top: 50%;
  transform: translateY(-50%);
  width: var(--hamburger-width);

  @media (--lg) {
    display: none !important;
  }

  &:hover {
    .hamburger__line {
      background-color: var(--secondary-color);

      &::before,
      &::after {
        background-color: var(--secondary-color);
      }
    }
  }

  &--active {
    .hamburger__line {
      height: 0;

      &::before,
      &::after {
        bottom: 0;
        top: 0;
      }

      &::before {
        transform: rotate(45deg);
      }

      &::after {
        transform: rotate(-45deg);
      }
    }
  }
}

.hamburger__line {
  @apply (--hamburger-line);

  &::before {
    @apply (--hamburger-line);
    bottom: var(--hamburger-offset);
    content: "";
    left: 0;
    position: absolute;
  }

  &::after {
    @apply (--hamburger-line);
    content: "";
    left: 0;
    position: absolute;
    top: var(--hamburger-offset);
  }
}
