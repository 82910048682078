/* Structure */

.footer {
  overflow: hidden;
}

.footer-top {
  background-color: var(--primary-color-dark);
  padding: var(--gap-xl) 0;
}

/* Typography */

.footer-header {
  color: var(--text-color-darker);
  font-family: var(--primary-font);
  font-size: calc(1.9rem + 0.1vw);
  font-weight: 700;
  margin-bottom: var(--gap-sm);
  text-align: center;
  text-transform: uppercase;
}

/* Footer Logo */

:root {
  --footer-logo-height: 10.8rem;
  --footer-logo-width: 15rem;
}

.footer-logo {
  margin-bottom: var(--gap);
}

.footer-logo__link {
  align-items: center;
  display: flex;
  height: var(--footer-logo-height);
  justify-content: center;
  margin: auto;
  width: var(--footer-logo-width);
}

.footer-logo__icon {
  fill: #fff;
  height: 100%;
  width: 100%;
}

/* Footer Info */

.footer-info-cols {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 calc(var(--gap-xl) * -1);
}

.footer-info-col {
  flex: 1 0 100%;
  padding: 0 var(--gap-xl);

  @media (--md) {
    flex: inherit;
  }

  &:not(:last-child) {
    margin-bottom: var(--gap);

    @media (--md) {
      border-right: 0.1rem solid rgba(255, 255, 255, 0.5);
      margin-bottom: 0;
    }
  }
}

.footer-info-icon {
  @apply (--grunge-circle-mask);
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  height: 6rem;
  justify-content: center;
  margin: 0 auto var(--gap-xs);
  width: 6rem;

  svg {
    fill: var(--primary-color-dark);
    height: 50%;
    width: 50%;
  }
}

.footer-info__text {
  color: #fff;
  font-size: 1.9rem;
  text-align: center;

  &:last-child {
    margin-bottom: 0;
  }
}

/* Affiliates */

.affiliates {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 calc(var(--gap) / 2 * -1) var(--gap);

  > * {
    padding: 0 calc(var(--gap) / 2);
  }
}

.affiliate__img {
  max-width: 15rem;
}

/* Copyright */

.footer-copyright {
  font-size: 1.4rem;
  text-align: center;

  a {
    text-transform: uppercase;
    transition: color var(--transition-dur);
  }

  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

/* Subscribe Form */

:root {
  --footer-subscribe-form-border-radius: 4rem;
  --footer-subscribe-form-input-height: 6rem;
  --footer-subscribe-form-btn-width: 20rem;
}

.footer__subscribe-form {
  .subscribe-form {
    position: relative;

    label {
      &.left {
        display: none;
      }
    }

    .clear {
      display: none;
    }

    fieldset {
      margin-bottom: 0;
    }

    .fields {
      display: block;
    }
  }

  .field.nocaptcha {
    display: grid;
    margin-top: 1em;
    place-content: center;
    width: 100%;
  }

  input {
    &.subscribe-form__input {
      border: 0.1rem solid var(--neutral-color-darkest);
      border-radius: var(--footer-subscribe-form-border-radius);
      color: var(--text-color-dark);
      font-size: 1.4rem;
      height: var(--footer-subscribe-form-input-height);
      margin-bottom: var(--gap-xs);
      padding: 0 var(--gap);

      @media (--sm) {
        border-bottom-right-radius: 0;
        border-right: 0;
        border-top-right-radius: 0;
        margin-bottom: 0;
        max-width: calc(100% - var(--footer-subscribe-form-btn-width));
      }

      &::-webkit-input-placeholder {
        color: var(--text-color-light);
      }

      &::-moz-placeholder {
        color: var(--text-color-light);
      }

      &:-ms-input-placeholder {
        color: var(--text-color-light);
      }

      &:-moz-placeholder {
        color: var(--text-color-light);
      }
    }
  }

  .subscribe-form__btn {
    background-color: var(--tertiary-color);
    border-radius: var(--footer-subscribe-form-border-radius);
    font-weight: 400;
    height: var(--footer-subscribe-form-input-height);
    padding: 0;
    transition: background-color var(--transition-dur);
    width: 100%;

    @media (--sm) {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: var(--footer-subscribe-form-btn-width);
    }

    &:hover {
      background-color: var(--primary-color);
    }
  }

  .subscribe-form__checkbox {
    display: none;
  }
}
