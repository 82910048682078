.reviews-wrap {
  @apply (--white-to-yellow-gradient-bg);
  min-height: 40rem;
  padding: var(--gap) 0;

  @media (--md) {
    padding: var(--gap-xl) 0;
  }
}

/* Cards */

:root {
  --review-meta-width: 25rem;
}

.review-card {
  background-color: #fff;
  border-radius: var(--border-radius);
  box-shadow: 0.2rem 0.2rem 0.5rem rgba(0, 0, 0, 0.2);
  display: flex;
  flex-wrap: wrap;
  padding: var(--gap);

  &:not(:last-child) {
    margin-bottom: var(--gap-sm);
  }
}

.review-card__meta {
  flex: 1 0 100%;
  margin-bottom: var(--gap-xs);

  @media (--md) {
    flex-basis: var(--review-meta-width);
    margin-bottom: 0;
    max-width: var(--review-meta-width);
    padding-right: var(--gap-xl);
  }
}

.review-card__details {
  flex: 1 0 100%;

  @media (--md) {
    flex-basis: calc(100% - var(--review-meta-width));
  }

  > * {
    &:not(:last-child) {
      margin-bottom: var(--gap-sm);
    }
  }
}

/* Typography */

.review__title {
  color: var(--primary-color);
  font-size: calc(2.4rem + 0.15vw);
  margin-bottom: var(--gap-xs);
  padding-bottom: var(--gap-xs);
  position: relative;

  &::after {
    @apply (--grunge-rectangle-mask);
    background-color: var(--primary-color);
    bottom: 0;
    content: "";
    height: 0.4rem;
    left: 0;
    position: absolute;
    width: 6rem;
  }
}

.review__location {
  color: var(--text-color-darker);
  display: flex;
  font-size: calc(1.8rem + 0.1vw);
  font-weight: 700;
}

.review__location__icon {
  fill: var(--text-color-darker);
  height: 1.6rem;
  margin-right: var(--gap-xxxs);
  position: relative;
  top: 0.6rem;
  width: 1.6rem;
}

.review__date {
  font-size: calc(1.8rem + 0.1vw);
}

/* Review Full */

.review-full {
  display: flex;
  flex-wrap: wrap;

  &:not(:last-child) {
    margin-bottom: var(--gap-sm);
  }
}

.review-full__meta {
  flex: 1 0 100%;
  margin-bottom: var(--gap-xs);

  @media (--md) {
    flex-basis: var(--review-meta-width);
    margin-bottom: 0;
    max-width: var(--review-meta-width);
    padding-right: var(--gap-xl);
  }
}

.review-full__details {
  flex: 1 0 100%;

  @media (--md) {
    flex-basis: calc(100% - var(--review-meta-width));
  }

  > * {
    &:not(:last-child) {
      margin-bottom: var(--gap-sm);
    }
  }
}

/* Ratings Field */

.field {
  &.ratings-field {
    grid-column: 1 / -1 !important;

    label {
      &.left {
        color: var(--secondary-color);
        display: block;
        font-size: 1.7rem;
        font-weight: 700;
        margin-bottom: var(--gap-xxs) !important;
        text-align: center;
      }
    }
  }
}

ul {
  &.ratings-field {
    direction: rtl;
    display: flex;
    justify-content: center;
    margin-bottom: 0;
    unicode-bidi: bidi-override;

    li {
      margin: 0;
      padding: 0;
    }

    label {
      align-items: center;
      cursor: pointer;
      display: flex;
      font-size: 0;
      justify-content: center;
      margin: 0 calc(var(--gap-xxs) / 2);

      &::after {
        background-color: var(--neutral-color);
        background-image: url("../img/review-fish.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 80% auto;
        border-radius: 50%;
        content: "";
        height: 4rem;
        position: relative;
        transition: background-color var(--transition-dur);
        width: 4rem;

        @media (--md) {
          height: 6rem;
          width: 6rem;
        }
      }

      &:hover ~ label {
        &::after {
          background-color: var(--secondary-color);
        }
      }

      &:hover {
        &::after {
          background-color: var(--tertiary-color);
        }
      }
    }

    input {
      display: none;
      position: relative;

      &:checked {
        & ~ label {
          &::after {
            background-color: var(--secondary-color);
          }
        }
      }
    }
  }
}
