:root {
  --grunge-circle-mask {
    mask-image: url("../icons/grunge-circle-mask.svg");
    mask-repeat: no-repeat;
    mask-size: cover;
  }

  --grunge-rectangle-mask {
    mask-image: url("../icons/grunge-rectangle-mask.svg");
    mask-repeat: no-repeat;
    mask-size: cover;
  }

  --hero-mask {
    mask: url("../icons/hero-mask.svg");
    mask-position: bottom;
    mask-size: cover;
  }

  --white-to-yellow-gradient-bg {
    background: linear-gradient(
      #fff 0%,
      #fff 20%,
      var(--tertiary-color) 40%,
      var(--tertiary-color) 100%
    );
  }
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.has-sticky-header {
  .main {
    margin-top: calc(var(--header-height-sm) + var(--header-offset) * -1);
  }
}

.header-and-main {
  flex: 1 0 auto;
  overflow: hidden;
}

.main {
  margin-top: calc(var(--header-height-sm) + var(--header-offset) * -1);
  min-height: 40rem;
  transition: margin-top var(--transition-dur);

  @media (--xxl) {
    margin-top: calc(var(--header-height) + var(--header-offset) * -1);
  }
}

/* Block Stuff */

.block {
  padding: var(--gap) 0;

  &--lg {
    padding: var(--gap-lg) 0;
  }

  &--xl {
    padding: var(--gap-xl) 0;
  }

  &--xxl {
    padding: var(--gap-xxl) 0;
  }

  &--xl-on-md {
    @media (--md) {
      padding: var(--gap-xl) 0;
    }
    padding: var(--gap) 0;
  }

  &--white-color {
    background-color: #fff;
  }

  &--neutral-color {
    background-color: var(--neutral-color-lighter);
  }

  &--primary-color {
    background-color: var(--primary-color);
  }

  &--secondary-color {
    background-color: var(--secondary-color);
  }

  &--tertiary-color {
    background-color: var(--tertiary-color);
  }

  &--dark-color {
    background-color: var(--dark-color);
  }
}

/* Wrapper Stuff */

.wrapper {
  margin: 0 auto;
  max-width: var(--max-width);
  padding: 0 var(--gap);
  position: relative;
  width: 100%;

  &--sm {
    max-width: var(--max-width-sm);
  }

  &--xs {
    max-width: var(--max-width-xs);
  }

  &--lg {
    max-width: var(--max-width-lg);
  }

  &--xl {
    max-width: var(--max-width-xl);
  }

  &--xl-on-md {
    padding: 0 var(--gap);

    @media (--md) {
      padding: 0 var(--gap-xl);
    }
  }
}

.wrapper--full-on-mobile {
  padding: 0;

  @media (--sm) {
    padding: 0 var(--gap);
  }

  &.wrapper--xl-on-md {
    @media (--md) {
      padding: 0 var(--gap-xl);
    }
  }
}
