:root {
  --filter-header-sidebar-width: 20rem;
  --filter-header-sidebar-width-lg: 35rem;
}

.filter-header {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 calc(var(--gap) / 2 * -1) var(--gap);

  > * {
    padding: 0 calc(var(--gap) / 2);
  }

  &--sidebar-lg {
    .filter-header__main {
      @media (--xl) {
        flex-basis: calc(100% - var(--filter-header-sidebar-width-lg));
      }
    }

    .filter-header__sidebar {
      @media (--xl) {
        flex-basis: var(--filter-header-sidebar-width-lg);
      }
    }
  }
}

.filter-header__main {
  flex: 1 0 100%;

  @media (--xl) {
    flex-basis: calc(100% - var(--filter-header-sidebar-width));
  }
}

.filter-header__sidebar {
  flex: 1 0 100%;
  margin-top: var(--gap-sm);

  @media (--xl) {
    flex-basis: var(--filter-header-sidebar-width);
    margin-top: 0;
  }
}

.filter-header__filter-form {
  .fields {
    grid-template-columns: 1fr;

    @media (--lg) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}

/* Nav */

.filter-header-nav__items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media (--xl) {
    justify-content: flex-end;
  }
}

.filter-header-nav__item {
  align-self: center;
  justify-self: center;
  width: 100%;

  @media (--md) {
    width: auto;
  }
}

.filter-header-nav__link {
  color: var(--tertiary-color);
  display: block;
  font-size: 1.5rem;
  font-weight: 700;
  text-transform: uppercase;
  transition: color var(--transition-dur);

  &--with-icon {
    align-items: center;
    display: flex;

    > * {
      &:first-child {
        margin-right: var(--gap-xxs);
      }
    }
  }

  &:hover {
    color: var(--primary-color);
  }
}

.filter-header-nav__icon {
  align-items: center;
  display: flex;
  height: 2.8rem;
  justify-content: center;
  width: 2.8rem;

  svg {
    fill: var(--tertiary-color);
    height: 100%;
    width: 100%;
  }
}
