.meta {
  color: var(--secondary-color);
  display: flex;
  flex-wrap: wrap;
  font-size: 1.6rem;
  font-weight: 500;
  margin-bottom: var(--gap-xs);
  text-transform: uppercase;

  > * {
    &:not(:last-child) {
      margin-right: var(--gap-xs);
    }
  }
}

.meta__item {
  align-items: center;
  display: flex;

  > * {
    &:not(:last-child) {
      margin-right: var(--gap-xxxs);
    }
  }
}

.meta__icon {
  align-items: center;
  display: flex;
  height: 1.6rem;
  justify-content: center;
  width: 1.6rem;

  svg {
    fill: var(--secondary-color);
    height: 100%;
    width: 100%;
  }
}
