body {
  color: var(--text-color);
  font-family: var(--primary-font);
  font-size: calc(1.6rem + 0.1vw);
  font-weight: 400;
  line-height: 1.5;
  text-rendering: optimizeLegibility;
}

p,
pre,
address {
  margin: 0 0 var(--gap);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--text-color-darker);
  font-family: var(--secondary-font);
  font-weight: 700;
  line-height: 1.2;
  margin: 0;
  text-transform: uppercase;
  word-break: break-word;
}

h1 {
  font-size: calc(4.2rem + 0.15vw);

  @media (--md) {
    font-size: calc(6rem + 0.3vw);
  }
}

h2 {
  font-size: calc(3.2rem + 0.15vw);

  @media (--md) {
    font-size: calc(4.8rem + 0.3vw);
  }
}

h3 {
  font-size: calc(2.8rem + 0.15vw);

  @media (--md) {
    font-size: calc(4.2rem + 0.15vw);
  }
}

h4 {
  font-size: calc(2.4rem + 0.15vw);

  @media (--md) {
    font-size: calc(3.6rem + 0.15vw);
  }
}

h5 {
  font-size: calc(2.1rem + 0.15vw);

  @media (--md) {
    font-size: calc(2.8rem + 0.15vw);
  }
}

h6 {
  font-size: calc(1.8rem + 0.15vw);

  @media (--md) {
    font-size: calc(2.4rem + 0.15vw);
  }
}

a {
  color: var(--primary-color);
  outline: none;
  text-decoration: none;

  &:hover {
    color: var(--secondary-color);
  }
}

strong {
  font-weight: 700;
}

small {
  font-size: calc(1.3rem + 0.1vw);
}

code {
  color: var(--text-color-light);
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-weight: 400;
}

button {
  cursor: pointer;
  font-family: var(--primary-font);
}
