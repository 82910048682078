:root {
  --logo-height: 48rem;
  --logo-height-sm: 30rem;
  --logo-transition-speed: 0.2s;
  --logo-width: 45rem;
  --logo-width-sm: 27rem;
}

.has-sticky-header {
  .logo {
    background-size: var(--logo-width-sm) var(--logo-height-sm);
    height: var(--logo-height-sm);
    top: calc(var(--logo-height-sm) / 2.2 * -1);
    width: var(--logo-width-sm);
  }
}

.logo {
  align-items: center;
  background: url("../img/logo-with-bg.png");
  background-repeat: no-repeat;
  background-size: var(--logo-width-sm) var(--logo-height-sm);
  display: flex;
  height: var(--logo-height-sm);
  justify-content: center;
  left: calc(var(--gap) * -1);
  position: absolute;
  top: calc(var(--logo-height-sm) / 2.2 * -1);
  transition: all var(--logo-transition-speed);
  width: var(--logo-width-sm);
  z-index: 999;

  @media (--lg) {
    left: 50%;
    transform: translateX(-50%);
  }

  @media (--xxl) {
    background-size: var(--logo-width) var(--logo-height);
    height: var(--logo-height);
    top: calc(var(--logo-height) / 2.2 * -1);
    width: var(--logo-width);
  }
}
