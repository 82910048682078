.media-items-wrap {
  position: relative;
}

/* Image */

.media-item__img {
  border-radius: var(--border-radius);
}

/* Controls */

.media-items__controls {
  display: flex;
  justify-content: space-between;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  z-index: 10;
}

.media-items__control {
  @apply (--grunge-circle-mask);
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
  display: none;
  height: 5rem;
  justify-content: center;
  position: relative;
  transition: background-color var(--transition-dur);
  width: 5rem;

  @media (--sm) {
    display: flex;
  }

  &:hover {
    background-color: var(--tertiary-color);
  }

  &:first-child {
    margin-left: var(--gap-xs);

    svg {
      left: -0.2rem;
    }
  }

  &:last-child {
    margin-right: var(--gap-xs);

    svg {
      right: -0.2rem;
    }
  }

  svg {
    fill: #000;
    height: 55%;
    position: relative;
    width: 55%;
  }
}

/* Nav */

.media-items__nav {
  bottom: var(--gap-xs);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 100%;
}

.media-items__nav-btn {
  border: 0.2rem solid #fff;
  border-radius: 50%;
  height: 2.4rem;
  margin: 0 0.5rem;
  transition: background-color var(--transition-dur);
  width: 2.4rem;

  &.tns-nav-active,
  &:hover {
    @apply (--grunge-circle-mask);
    background-color: #fff;
  }
}
