.page-overlay {
  background-color: var(--dark-color);
  height: 100%;
  opacity: 0;
  position: fixed;
  transition: opacity var(--transition-dur);
  visibility: hidden;
  width: 100%;
  z-index: 9999;

  &--active {
    opacity: 0.8;
    visibility: visible;
  }
}
