.results-nav-wrap {
  background-color: var(--neutral-color-lighter);
  display: none;
  padding: var(--gap-xl) 0;

  &--offset-top {
    margin-top: calc(var(--gap-xxxl) * -1);
    padding: calc(var(--gap-xl) * 2) 0 var(--gap-xl);
  }

  @media (--md) {
    display: block;
  }
}

.results-nav {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
