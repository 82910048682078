.menus-filter-form {
  margin-bottom: 0;
  position: relative;

  label {
    display: none;

    &.left {
      display: none;
    }
  }

  fieldset {
    margin-bottom: 0;
  }

  .btn-toolbar {
    bottom: 0;
    position: absolute;
    right: 0;

    @media (--xl) {
      bottom: inherit;
      top: 0;
    }
  }

  .fields {
    grid-gap: var(--gap-xs) var(--gap);
  }

  .field {
    &.filter-form__select,
    &.fieldgroup {
      grid-column: auto;
    }
  }

  .middleColumn {
    &.fieldgroup {
      display: grid;
      grid-gap: 0 var(--gap-sm);
      grid-template-columns: 1fr 1fr;
    }
  }

  .field {
    &.filter-form--full {
      grid-column: 1 / -1;
    }
  }
}
