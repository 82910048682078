:root {
  --filter-form-input-height: 5rem;

  --filter-form-input-styles {
    appearance: none;
    background-color: #fff;
    border: 0.1rem solid var(--neutral-color);
    border-radius: 0;
    color: var(--text-color);
    font-size: 1.5rem;
    font-weight: 600;
    height: var(--filter-form-input-height);
  }
}

.filter-form {
  margin-bottom: 0;
  position: relative;

  label {
    display: none;

    &.left {
      display: none;
    }
  }

  fieldset {
    margin-bottom: 0;
  }

  .btn-toolbar {
    bottom: 0;
    position: absolute;
    right: 0;

    @media (--xl) {
      bottom: inherit;
      top: 0;
    }
  }

  .fields {
    grid-gap: var(--gap-xs) var(--gap);
  }

  .field {
    &.filter-form__select,
    &.fieldgroup {
      grid-column: auto;
    }
  }

  .middleColumn {
    &.fieldgroup {
      display: grid;
      grid-gap: 0 var(--gap-sm);
      grid-template-columns: 1fr 1fr;
    }
  }

  .field {
    &.filter-form--full {
      grid-column: 1 / -1;
    }
  }
}

.menu-page-filters-wrap {
  grid-gap: var(--gap-xs) var(--gap);
}

select {
  &.filter-form__select {
    @apply (--filter-form-input-styles);
    background-image: url("../img/angle-down.png");
    background-position: right var(--gap-xs) center;
    background-repeat: no-repeat;
    background-size: 2.1rem 1.1rem;
    padding: 0 calc(var(--filter-icon-size) + var(--gap)) 0 var(--gap-xs);
  }
}

input {
  &.filter-form__input {
    @apply (--filter-form-input-styles);
    padding: 0 var(--gap-xs);

    &--search {
      padding-right: calc(var(--filter-form-input-height) + var(--gap-xs));
    }

    &::-webkit-input-placeholder {
      color: var(--text-color);
    }

    &::-moz-placeholder {
      color: var(--text-color);
    }

    &:-ms-input-placeholder {
      color: var(--text-color);
    }

    &:-moz-placeholder {
      color: var(--text-color);
    }

    &:focus {
      &::-webkit-input-placeholder {
        color: var(--text-color-lighter);
      }

      &::-moz-placeholder {
        color: var(--text-color-lighter);
      }

      &:-ms-input-placeholder {
        color: var(--text-color-lighter);
      }

      &:-moz-placeholder {
        color: var(--text-color-lighter);
      }
    }
  }
}

div.filter-form__input--search {
  grid-column: span 1;
  position: relative;
}

.filter-form__action {
  background-color: var(--secondary-color);
  background-image: url("../img/search.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 2.1rem 2.1rem;
  border-radius: 0;
  content: "";
  height: var(--filter-form-input-height);
  min-width: auto;
  padding: 0;
  text-indent: -9999px;
  width: var(--filter-form-input-height);

  &:hover {
    background-color: var(--primary-color);
  }
}

.filter-form__submit {
  display: none;
}

.dropdown.filter-form__select.filter-form--full {
  text-transform: uppercase;
}
