:root {
  --hero-carousel-height: 70vh;
  --hero-carousel-min-height: 50rem;
}

/* Hero Carousel */

.hero-carousel-wrap {
  @apply (--hero-mask);
  background-color: var(--tertiary-color);
  height: var(--hero-carousel-height);
  min-height: var(--hero-carousel-min-height);
  position: relative;
  width: 100vw;

  .tns-outer,
  .tns-ovh,
  .tns-inner {
    height: 100%;
    position: absolute;
    width: 100%;
  }
}

.hero-carousel {
  height: var(--hero-carousel-height);
  min-height: var(--hero-carousel-min-height);
}

.hero-carousel__item {
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  height: 100%;
  justify-content: center;
  position: relative;
  width: 100%;
}

/* Home Header */

.hero-carousel-header {
  left: 50%;
  max-width: calc(100% - var(--gap) * 2);
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  z-index: 20;

  @media (--sm) {
    max-width: calc(100% - var(--gap-xxxl) * 2);
  }
}

.hero-carousel-header__title {
  color: var(--tertiary-color);
  font-size: calc(3rem + 0.3vw);
  line-height: 1;
  text-align: center;
  text-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
  text-transform: uppercase;

  @media (--md) {
    font-size: calc(5rem + 0.5vw);
  }

  @media (--xl) {
    font-size: calc(7rem + 0.5vw);
  }
}

.hero-carousel-header__title__lg {
  font-size: calc(5.5rem + 0.3vw);

  @media (--md) {
    font-size: calc(9rem + 0.5vw);
  }

  @media (--xl) {
    font-size: calc(13.5rem + 0.5vw);
  }
}

/* Page Header */

.hero-carousel__content {
  left: 50%;
  max-width: var(--max-width-sm);
  padding: 0 var(--gap);
  position: relative;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  z-index: 20;

  @media (--sm) {
    padding: 0 var(--gap-xxxl);
  }

  @media (--xl) {
    padding: 0 var(--gap-xxl);
  }
}

.hero-carousel__content__title {
  line-height: 1;
  text-align: center;
  text-shadow: 0 0 0.6rem rgba(0, 0, 0, 0.15);

  a {
    color: #fff;
    transition: color var(--transition-dur);

    &:hover {
      color: var(--primary-color);
    }
  }
}

/* Controls */

.hero-carousel__control {
  @apply (--grunge-circle-mask);
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
  display: none;
  height: 6rem;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: background-color var(--transition-dur);
  width: 6rem;
  z-index: 10;

  &--prev {
    left: 0;
  }

  &--next {
    right: 0;
  }

  @media (--sm) {
    display: flex;
  }

  @media (--xl) {
    height: 8rem;
    width: 8rem;
  }

  &:hover {
    background-color: var(--tertiary-color);
  }

  &:first-child {
    margin-left: var(--gap);

    svg {
      left: -0.2rem;
    }
  }

  &:last-child {
    margin-right: var(--gap);

    svg {
      right: -0.2rem;
    }
  }

  svg {
    fill: #000;
    height: 50%;
    position: relative;
    width: 50%;
  }
}

/* Nav */

.hero-carousel__nav {
  bottom: var(--gap-lg);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 100%;
}

.hero-carousel__nav-btn {
  border: 0.2rem solid #fff;
  border-radius: 50%;
  height: 2.4rem;
  margin: 0 0.5rem;
  transition: background-color var(--transition-dur);
  width: 2.4rem;

  &.tns-nav-active,
  &:hover {
    @apply (--grunge-circle-mask);
    background-color: #fff;
  }
}
