/* Typography Classes */

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-sm {
  font-size: 1.4rem;
}

.reversed-color {
  color: #fff;
}

.primary-color {
  color: var(--primary-color);
}

.secondary-color {
  color: var(--secondary-color);
}

.tertiary-color {
  color: var(--tertiary-color);
}

.all-caps {
  text-transform: uppercase;
}

/* Hide / Show Class */

.hide {
  display: none;
}
