:root {
  --home-latest-offset: 14rem;
}

/* Intros */

.home-intros-wrap {
  background-image: url("../img/fish.png"), url("../img/intros-texture.png");
  background-position: right var(--gap-sm), left var(--gap);
  background-repeat: no-repeat;
  background-size: 30% auto, 50% auto;
  padding: var(--gap-xl) 0 0 0;
  position: relative;
  z-index: 10;
}

.home-intros-title {
  color: var(--primary-color);
  margin-bottom: var(--gap-xxxs);
  text-align: center;
}

.home-intros-content {
  font-size: 1.8rem;
  text-align: center;
}

.home-intros {
  display: flex;
  flex-wrap: wrap;
  margin: 0 calc(var(--gap-xs) / 2 * -1);
}

.home-intro {
  flex: 1 1 100%;
  height: 0;
  justify-content: center;
  margin-bottom: var(--gap-xs);
  padding: 0 calc(var(--gap-xs) / 2) 100%;
  position: relative;

  @media (--md) {
    flex: 1 1 calc(100% / 2);
    max-width: calc(100% / 2);
    padding: 0 calc(var(--gap-xs) / 2) calc(100% / 2);
  }

  @media (--xl) {
    flex: 1 1 calc(100% / 3);
    max-width: calc(100% / 3);
    padding: 0 calc(var(--gap-xs) / 2) calc(100% / 3);
  }
}

.home-intro__inner {
  background-color: #fff;
  border-radius: var(--border-radius);
  height: 0;
  padding-bottom: 100%;
  position: relative;
}

.home-intro__bg {
  background-color: var(--primary-color-dark);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: var(--border-radius);
  display: flex;
  flex-direction: column;
  height: 95%;
  justify-content: space-between;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 95%;
}

.home-intro__link {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  position: absolute;
  width: 100%;

  &:hover {
    .home-intro__title {
      color: var(--tertiary-color);
    }
  }
}

.home-intro__icon {
  @apply (--grunge-circle-mask);
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  height: 9rem;
  justify-content: center;
  margin: var(--gap-xs) 0 0 var(--gap-xs);
  transition: background-color var(--transition-dur);
  width: 9rem;

  svg {
    fill: var(--dark-color);
    height: 50%;
    width: 50%;
  }
}

.home-intro__title {
  color: #fff;
  font-size: calc(3.6rem + 0.15vw);
  hyphens: auto;
  padding: var(--gap);
  transition: color var(--transition-dur);
}

/* Home Latest */

.home-latest-wrap {
  background-color: var(--tertiary-color);
  background-image: url("../img/yellow-textured-bg.jpg");
  background-position: center;
  background-repeat: repeat;
  background-size: cover;
  margin-top: calc((var(--home-latest-offset) + var(--gap)) * -1);
  padding: calc(var(--home-latest-offset) + var(--gap-xl)) 0
    calc(var(--home-latest-offset) + var(--gap-xxxl));
  position: relative;

  &::after {
    background-image: url("../img/fish-white.png");
    background-position: center top;
    background-repeat: no-repeat;
    background-size: 70% auto;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.home-latest__wrapper {
  z-index: 10;
}

.home-latest-title {
  color: #fff;
  margin-bottom: var(--gap-xs);
  text-align: center;
}

/* Home Cards */

.home-cards-wrap {
  margin-top: calc((var(--home-latest-offset) + var(--gap)) * -1);
  padding-bottom: var(--gap);
  position: relative;
  z-index: 10;

  @media (--md) {
    padding-bottom: var(--gap-xxl);
  }
}

.home-cards {
  display: flex;
  flex-wrap: wrap;
  margin: 0 calc(var(--gap-sm) / 2 * -1);
}

.home-card {
  flex: 1 1 100%;
  margin-bottom: var(--gap-sm);
  padding: 0 calc(var(--gap-sm) / 2);
  position: relative;

  @media (--md) {
    flex: 1 1 calc(100% / 2);
    max-width: calc(100% / 2);
  }

  @media (--xl) {
    flex: 1 1 calc(100% / 3);
    max-width: calc(100% / 3);
  }
}

.home-card__link {
  background-color: var(--primary-color-dark);
  border-radius: var(--border-radius);
  display: block;
  height: 100%;

  &:hover {
    .home-card__title {
      color: var(--tertiary-color);
    }
  }
}

.home-card__thumb {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
  height: 30rem;
  position: relative;
  width: 100%;
}

.home-card__details {
  padding: var(--gap);

  > * {
    &:not(:last-child) {
      margin-bottom: var(--gap-xs);
    }
  }
}

.home-card__title {
  color: #fff;
  font-family: var(--primary-font);
  font-size: 2rem;
  font-weight: 700;
  text-transform: inherit;
  transition: color var(--transition-dur);
}

.home-card__meta {
  color: #fff;
}

.home-card__cat-icon {
  align-items: center;
  bottom: var(--gap-xxs);
  display: flex;
  height: 2.1rem;
  justify-content: center;
  position: absolute;
  right: var(--gap-sm);
  width: 2.1rem;

  svg {
    fill: #fff;
    height: 100%;
    width: 100%;
  }
}

.home-card__site-cred {
  align-items: center;
  display: flex;
  justify-content: center;
  left: var(--gap-xs);
  position: absolute;
  top: var(--gap-xs);
}

/* Home Story */

.home-story-wrap {
  background-attachment: fixed;
  background-color: var(--primary-color-darker);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 20rem 0;
}

.home-story-header {
  margin-bottom: var(--gap-xs);

  h2 {
    line-height: 0.6;
    text-align: center;
  }
}

.home-story__year {
  color: rgba(255, 255, 255, 0.6);
  display: block;
  font-size: calc(6rem + 0.1vw);
  position: relative;

  @media (--md) {
    font-size: calc(9.6rem + 0.3vw);
  }
}

.home-story__title {
  color: #fff;
  display: block;
  font-size: calc(3.2rem + 0.1vw);
  position: relative;
  z-index: 10;

  @media (--md) {
    font-size: calc(4.2rem + 0.3vw);
  }
}

.home-story-content {
  color: #fff;
  margin-bottom: var(--gap);
  text-align: center;
}

/* Weather Widget */

:root {
  --weather-widget-conditions-icon-size-sm: 4.5rem;
  --weather-widget-conditions-icon-size: 6.5rem;
  --weather-widget-close-icon-size: 4.2rem;
}

.weather-widget {
  align-items: center;
  background-color: var(--primary-color-dark);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  padding: var(--gap-sm) var(--gap);
  position: fixed;
  right: 0;
  transition: opacity var(--transition-dur);
  width: 100%;
  z-index: 777;

  @media (--md) {
    border: 0.1rem solid #fff;
    border-radius: var(--border-radius);
    bottom: inherit;
    box-shadow: 0.4rem 0.4rem 0.4rem rgba(0, 0, 0, 0.4);
    justify-content: flex-start;
    left: inherit;
    margin: 0 calc(var(--gap-sm) / 2 * -1);
    padding: var(--gap);
    right: var(--gap);
    top: 50%;
    transform: translateY(-50%);
    width: inherit;
  }

  > * {
    padding: 0 calc(var(--gap-sm) / 2);
  }

  &--hide {
    opacity: 0;
    visibility: hidden;
  }
}

.weather-widget__icon {
  align-items: center;
  border: 0.1rem solid #fff;
  border-radius: 50%;
  display: flex;
  height: 100%;
  max-height: var(--weather-widget-conditions-icon-size-sm);
  max-width: var(--weather-widget-conditions-icon-size-sm);
  justify-content: center;
  width: 100%;

  @media (--md) {
    border: 0.2rem solid #fff;
    max-height: var(--weather-widget-conditions-icon-size);
    max-width: var(--weather-widget-conditions-icon-size);
  }

  svg {
    fill: #fff;
    height: 90%;
    width: 90%;
  }
}

.weather-widget__temp {
  color: #fff;
  font-size: 2.1rem;
  font-weight: 700;
  line-height: 1.3;

  @media (--md) {
    font-size: 4.8rem;
    line-height: 1.1;
  }
}

.weather-widget__conditions {
  color: #fff;
  font-size: 1.4rem;
  line-height: 1;
  text-transform: uppercase;
}

.weather-widget__close {
  align-items: center;
  background-color: var(--tertiary-color);
  display: flex;
  height: var(--weather-widget-close-icon-size);
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  transition: background-color var(--transition-dur);
  width: var(--weather-widget-close-icon-size);

  @media (--md) {
    border-radius: 50%;
    box-shadow: 0.2rem 0.2rem 0.2rem rgba(0, 0, 0, 0.2);
    right: calc(var(--weather-widget-close-icon-size) / 2 * -1);
    top: calc(var(--weather-widget-close-icon-size) / 2 * -1);
  }

  &:hover {
    background-color: var(--primary-color);
  }

  svg {
    fill: #fff;
    height: 70%;
    width: 70%;
  }
}
