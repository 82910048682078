.breadcrumbs {
  &--reversed {
    .breadcrumbs__item {
      color: rgba(255, 255, 255, 0.7);
    }

    .breadcrumbs__link {
      color: #fff;

      &:hover {
        color: var(--tertiary-color);
      }
    }
  }
}

.breadcrumbs__items {
  display: flex;
  flex-wrap: wrap;
}

.breadcrumbs__item {
  align-items: center;
  color: var(--text-color-lighter);
  display: flex;
  font-size: 1.6rem;
  font-weight: 600;
  text-transform: uppercase;

  &:not(:last-child) {
    &::after {
      color: var(--text-color-lighter);
      content: ">";
      margin: 0 var(--gap-xs) 0 var(--gap-xxs);
    }
  }
}

.breadcrumbs__link {
  color: var(--text-color-dark);
  display: block;
  transition: color var(--transition-dur);

  &:hover {
    color: var(--primary-color);
  }
}
