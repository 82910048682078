.tingle-modal * {
  box-sizing: border-box;
}

.tingle-modal {
  align-items: center;
  background: rgba(0, 0, 0, 0.85);
  bottom: 0;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  left: 0;
  opacity: 0;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  position: fixed;
  right: 0;
  top: 0;
  user-select: none;
  visibility: hidden;
  z-index: 1000;
}

.tingle-modal--noClose .tingle-modal__close,
.tingle-modal__closeLabel {
  display: none;
}

.tingle-modal--confirm .tingle-modal-box {
  text-align: center;
}

.tingle-modal--noOverlayClose {
  cursor: default;
}

.tingle-modal__close {
  background-color: transparent;
  border: 0;
  color: #fff;
  cursor: pointer;
  height: 2rem;
  padding: 0;
  position: fixed;
  right: 2rem;
  top: 2rem;
  width: 2rem;
  z-index: 1000;
}

.tingle-modal__close svg * {
  fill: currentColor;
}

.tingle-modal__close:hover {
  color: #fff;
}

.tingle-modal-box {
  border-radius: 4px;
  cursor: auto;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-bottom: auto;
  margin-top: auto;
  opacity: 1;
  position: relative;
  width: 60%;
  will-change: transform, opacity;
}

.tingle-modal-box__content {
  padding: 3rem;
}

.tingle-modal-box__footer {
  background-color: #f5f5f5;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  cursor: auto;
  padding: 1.5rem 2rem;
  width: auto;
}

.tingle-modal-box__footer::after {
  clear: both;
  content: "";
  display: table;
}

.tingle-modal-box__footer--sticky {
  bottom: -200px;
  opacity: 1;
  position: fixed;
  transition: bottom 0.3s ease-in-out 0.3s;
  z-index: 10001;
}

.tingle-enabled {
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
}

.tingle-modal--visible .tingle-modal-box__footer {
  bottom: 0;
}

.tingle-enabled .tingle-content-wrapper {
  filter: blur(8px);
}

.tingle-modal--visible {
  opacity: 1;
  visibility: visible;
}

.tingle-modal--visible .tingle-modal-box {
  animation: scale 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55) forwards;
}

.tingle-modal--overflow {
  overflow-y: scroll;
  padding-top: 8vh;
}

.tingle-btn {
  background-color: grey;
  border: 0;
  box-shadow: none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  font-size: inherit;
  line-height: normal;
  margin: 0 0.5rem;
  padding: 1rem 2rem;
  text-decoration: none;
  transition: background-color 0.4s ease;
  vertical-align: middle;
}

.tingle-btn--primary {
  background-color: #3498db;
}

.tingle-btn--danger {
  background-color: #e74c3c;
}

.tingle-btn--default {
  background-color: #34495e;
}

.tingle-btn--pull-left {
  float: left;
}

.tingle-btn--pull-right {
  float: right;
}

@media (max-width: 540px) {
  .tingle-modal {
    display: block;
    padding-top: 60px;
    top: 0;
    width: 100%;
  }

  .tingle-modal-box {
    border-radius: 0;
    width: auto;
  }

  .tingle-modal-box__content {
    overflow-y: scroll;
  }

  .tingle-modal--noClose {
    top: 0;
  }

  .tingle-modal--noOverlayClose {
    padding-top: 0;
  }

  .tingle-modal-box__footer .tingle-btn {
    display: block;
    float: none;
    margin-bottom: 1rem;
    width: 100%;
  }

  .tingle-modal__close {
    background-color: #2c3e50;
    border: 0;
    box-shadow: none;
    color: #fff;
    display: block;
    left: 0;
    right: 0;
    top: 0;
    height: 60px;
    width: 100%;
  }

  .tingle-modal__closeLabel {
    display: inline-block;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-size: 1.6rem;
    vertical-align: middle;
  }

  .tingle-modal__closeIcon {
    display: inline-block;
    font-size: 0;
    margin-right: 0.8rem;
    vertical-align: middle;
    width: 1.6rem;
  }
}

@supports (
  (-webkit-backdrop-filter: blur(12px)) or (backdrop-filter: blur(12px))
) {
  .tingle-modal {
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
  }

  @media (max-width: 540px) {
    .tingle-modal {
      -webkit-backdrop-filter: blur(8px);
      backdrop-filter: blur(8px);
    }
  }

  .tingle-enabled .tingle-content-wrapper {
    filter: none;
  }
}

@keyframes scale {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}
