/* Detail Breadcrumbs */

.detail__breadcrumbs {
  margin-bottom: var(--gap);
}

/* Detail Structure */

.detail-cols {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 calc(var(--gap-xxl) / 2 * -1);
  position: relative;
  z-index: 1;
}

.detail-col {
  padding: 0 calc(var(--gap-xxl) / 2);

  &--main {
    flex: 1 0 100%;

    @media (--xl) {
      flex-basis: 55%;
      max-width: 55%;
    }
  }

  &--sidebar {
    flex: 1 0 100%;

    @media (--xl) {
      flex-basis: 45%;
    }
  }
}

/* Detail Header */

.detail-header {
  margin-bottom: var(--gap);

  > * {
    &:not(:last-child) {
      margin-bottom: var(--gap);
    }
  }
}

.detail-header__top {
  align-items: center;
  display: flex;
  margin: 0 calc(var(--gap-xs) / 2 * -1);

  > * {
    padding: 0 calc(var(--gap-xs) / 2);
  }
}

.detail-header__top__title {
  position: relative;

  &::after {
    @apply (--grunge-rectangle-mask);
    background-color: var(--secondary-color);
    bottom: calc(var(--gap-xxs) * -1);
    content: "";
    height: 0.3rem;
    left: calc(var(--gap-xs) / 2);
    position: absolute;
    width: 6rem;
  }

  h4 {
    color: var(--secondary-color);
    font-family: var(--primary-font);
    font-size: calc(2.1rem + 0.1vw);
    text-transform: uppercase;
  }
}

.detail-title {
  color: var(--primary-color);
}
