:root {
  --filter-icon-size: 1.6rem;
  --menu-icon-size: 3.2rem;
}

/* Structure */

.menu-page__wrapper {
  margin-top: calc(var(--gap-xxl) * -1);
}

.menu-page-content {
  background-color: #fff;
  border: 0.1rem solid var(--neutral-color);
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
  min-height: 40rem;
  padding: var(--gap);

  @media (--md) {
    padding: var(--gap) var(--gap) var(--gap-xl) var(--gap);
  }
}

/* Header */

.menu-page-header {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: var(--gap-xl);

  > * {
    flex: 1 0 100%;

    @media (--lg) {
      flex-basis: 50%;
    }
  }
}

.menu-page-nav {
  display: flex;
  justify-content: center;

  @media (--lg) {
    justify-content: flex-end;
  }
}

.grid-a {
  grid-area: a;
}

.grid-b{
  grid-area: b;
}

.grid-c{
  grid-area: c;
}

.grid-d{
  grid-area: d;
}

.menu-page-filters-wrap {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr;
  grid-template-areas:
      "a"
      "b"
      "c"
      "d";

  @media (--md) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "a b"
      "c c"
      "d d";
  }

  @media (--ll) {
    grid-gap: 0 var(--gap-sm);
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas: "a b c d";
  }
}

.menu-page-filters {
  display: flex;
  flex-wrap: wrap;
  margin: 0 calc(var(--gap-xs) / 2 * -1) var(--gap);

  @media (--lg) {
    margin-bottom: 0;
  }

  > * {
    flex: 1 0 100%;
    padding: 0 calc(var(--gap-xs) / 2);

    @media (--lg) {
      flex-basis: 50%;
    }
  }
}

.menu-page-filter {
  position: relative;
  /* max-width: 300px; */
}

/* Menu Categories/Items */

.menu-category {
  &:not(:last-child) {
    margin-bottom: var(--gap-xl);
  }
}

.menu-category__title {
  color: var(--primary-color);
  margin-bottom: var(--gap-lg);
  padding-bottom: var(--gap-xxs);
  position: relative;
  text-align: center;

  &::after {
    @apply (--grunge-rectangle-mask);
    background-color: var(--primary-color);
    bottom: calc(var(--gap-xxs) * -1);
    content: "";
    height: 0.8rem;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 12rem;
  }
}

.menu-category__description {
  padding: 0 var(--gap);
  text-align: center;
}

.menu-items {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin: 0 calc(var(--gap-xxl) / 2 * -1);
  padding: 0 var(--gap);
}

.menu-item {
  flex: 0 0 100%;
  padding: 0 calc(var(--gap-xxl) / 2);
  position: relative;

  @media (--lg) {
    flex-basis: 50%;
    max-width: 50%;
  }

  &--full {
    @media (--lg) {
      flex-basis: 100%;
      max-width: 100%;
    }
  }
}

.menu-item__inner {
  border-bottom: 0.1rem solid var(--neutral-color);
  padding: var(--gap) 0;
}

.menu-item__block {
  align-items: center;
  display: flex;
  justify-content: space-between;

  &--full {
    display: block;
  }
}

.menu-item__icon {
  align-items: center;
  background-color: var(--secondary-color);
  border-radius: 50%;
  display: flex;
  height: var(--menu-icon-size);
  justify-content: center;
  left: 0;
  position: absolute;
  top: var(--gap);
  width: var(--menu-icon-size);

  svg {
    fill: #fff;
    height: 65%;
    width: 65%;
  }
}

.menu-item__details {
  > * {
    &:not(:last-child) {
      margin-bottom: var(--gap-xxxs);
    }
  }
}

.menu-item__title {
  font-size: calc(2.1rem + 0.15vw);
  text-transform: inherit;
}

.menu-item__sub-title {
  color: var(--text-color-darker);
  font-family: var(--primary-font);
  font-size: 1.8rem;
  font-weight: 700;
  text-transform: inherit;
}

.menu-item__content {
  margin-bottom: 0;
}

.menu-item__price {
  font-family: var(--secondary-font);
  font-size: calc(2.1rem + 0.15vw);
  margin-left: var(--gap);
  white-space: nowrap;

  span {
    font-size: calc(1.5rem + 0.15vw);
    padding-left: 0.2rem;
    position: relative;
    top: -0.8rem;
  }
}

.menu-item__price-desc {
  color: var(--text-color-light);
  font-size: 1.2rem;
  text-align: right;
  text-transform: uppercase;
}

.menu-item__sub-category__title {
  color: var(--primary-color);
  font-size: 2.8rem;
  text-align: center;
}

.menu-item__sub-category__content {
  margin-bottom: 0;
  text-align: center;
}


.catering-packages .menu-item__price, .premiere-catering-packages .menu-item__price{
	display: none !important;
}


