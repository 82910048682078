.header {
  height: 100%;
  max-height: var(--header-height-sm);
  position: fixed;
  top: 0;
  transition: max-height var(--transition-dur);
  width: 100%;
  z-index: 888;

  @media (--xxl) {
    max-height: var(--header-height);
  }

  &--sticky {
    max-height: var(--header-height-sm);
  }
}

.header__inner {
  align-items: center;
  background-color: #fff;
  display: flex;
  height: 100%;
  mask: url("../icons/header-mask.svg");
  mask-position: bottom;
  mask-repeat: no-repeat;
  mask-size: cover;
  padding: 0 var(--gap);
  width: 100%;

  @media (--xl) {
    padding: 0 var(--gap-xl);
  }
}

.header__nav {
  position: relative;
  top: calc(var(--header-offset) / 2 * -1);
  width: 100%;

  > * {
    &:not(:last-child) {
      margin-bottom: var(--gap-xs);
    }
  }
}
