.hero {
  @apply (--hero-mask);
  align-items: center;
  background-image: url("../img/yellow-textured-bg.jpg");
  background-repeat: repeat;
  display: flex;
  justify-content: center;
  min-height: 50rem;
  position: relative;
  width: 100%;

  &--with-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &--fade-to-white {
    &::after {
      background: linear-gradient(transparent, #fff);
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    .hero__title {
      text-shadow: 0.2rem 0.2rem 1.5rem rgba(0, 0, 0, 0.3);
    }
  }
}

.hero__content {
  margin: auto;
  max-width: var(--max-width);
  padding: var(--gap);
  width: 100%;
  z-index: 1;

  @media (--md) {
    padding: var(--gap) var(--gap-xl);
  }
}

.hero__title {
  color: #fff;
  font-size: calc(2.8rem + 0.15vw);
  line-height: 1;
  text-align: center;
  word-break: break-word;

  @media (--md) {
    font-size: calc(4rem + 0.25vw);
  }

  @media (--xl) {
    font-size: calc(6rem + 0.5vw);
  }
}

.hero__subtitle {
  color: #fff;
  font-size: calc(3.2rem + 0.3vw);
  text-align: center;
}

.hero__text {
  color: #fff;
  font-size: calc(1.4rem + 0.1vw);
  margin-top: var(--gap-xxs);
  text-align: center;
}
