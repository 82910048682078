.events-wrap {
  @apply (--white-to-yellow-gradient-bg);
  min-height: 40rem;
  padding: var(--gap-xl) 0 0 0;
  position: relative;
  z-index: 1;
}

.events-wrap__wrapper {
  position: relative;
  z-index: 1;
}

/* Title */

.events-page__title {
  font-size: calc(2.4rem + 0.15vw);
  font-weight: 700;
  margin-bottom: var(--gap);
  text-align: center;
}

/* Nav */

.events-nav {
  background-color: #fff;
  margin-top: calc(var(--gap-xl) * 3 * -1);
  padding: calc(var(--gap-xl) * 3.5) 0 var(--gap-xl);
  position: relative;
}

/* Event Bar */

:root {
  --event-bar-height: 9.5rem;
}

.event-wrap {
  position: relative;
}

.event-bg {
  background-image: url("../img/event-fish.png");
  background-position: top right;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  max-width: 65%;
  position: absolute;
  right: 0;
  top: var(--gap-xl);
  width: 100%;
}

.event-bar {
  left: 50%;
  margin-top: calc(var(--event-bar-height) / 2 * -1);
  max-width: var(--max-width);
  min-height: var(--event-bar-height);
  padding: 0 var(--gap);
  position: relative;
  transform: translateX(-50%);
  width: 100%;
  z-index: 1;

  @media (--md) {
    padding: 0 var(--gap-xl);
  }
}

.event-bar__cols {
  align-items: center;
  background-image: url("../img/event-bar-bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 5rem;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  justify-content: center;
  min-height: var(--event-bar-height);
  padding: var(--gap-sm) var(--gap);
}

.event-bar__col {
  align-items: center;
  color: #fff;
  display: flex;
  flex: 1 0 100%;
  font-family: var(--secondary-font);
  font-size: calc(2.1rem + 0.1vw);
  height: 80%;
  justify-content: center;
  position: relative;
  text-transform: uppercase;

  @media (--md) {
    flex: inherit;
    justify-content: inherit;
  }

  @media (--lg) {
    font-size: calc(2.8rem + 0.3vw);
  }

  &:not(:last-child) {
    @media (--md) {
      margin-right: calc(var(--gap) * 2);
    }

    &::after {
      background-color: rgba(255, 255, 255, 0.9);
      content: "";
      display: none;
      height: 100%;
      min-height: calc(var(--event-bar-height) - var(--gap));
      position: absolute;
      right: calc(var(--gap) * -1);
      top: 50%;
      transform: translateY(-50%);
      width: 0.1rem;

      @media (--md) {
        display: block;
      }
    }
  }
}

.event-bar__icon {
  align-items: center;
  display: flex;
  height: 2.4rem;
  justify-content: center;
  margin-right: var(--gap-xs);
  width: 2.4rem;

  @media (--lg) {
    height: 4.8rem;
    width: 4.8rem;
  }

  svg {
    fill: #fff;
    height: 100%;
    width: 100%;
  }
}
