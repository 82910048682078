:root {
  --modal-close-size-sm: 4rem;
  --modal-close-size: 7rem;
  --modal-max-height: 60rem;
}

/* Modal Base */

.modal {
  background-color: #fff;
  border-radius: var(--border-radius);
  box-shadow: 0 0 20rem rgba(0, 0, 0, 0.1);
  height: auto;
  left: 50%;
  margin-top: -4rem;
  max-height: 100%;
  max-width: 100%;
  opacity: 0;
  overflow: scroll;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: margin calc(var(--transition-dur) * 1.5),
    opacity var(--transition-dur), visibility 0s;
  visibility: hidden;
  width: 100%;
  z-index: 9999;

  @media (--xs) {
    max-height: 85%;
    max-width: calc(var(--max-width) - (var(--gap-xl) * 2));
    width: 85%;
  }

  @media (--xl) {
    max-height: var(--modal-max-height);
    overflow: visible;
    width: 90%;
  }

  &--active {
    margin-top: 0;
    opacity: 1;
    visibility: visible;
  }

  &--sm {
    @media (--xs) {
      max-width: calc(var(--max-width-sm) - (var(--gap-xl) * 2));
    }
  }

  &--xs {
    @media (--xs) {
      max-width: calc(var(--max-width-xs) - (var(--gap-xl) * 2));
    }
  }
}

/* Content */

.modal__inner {
  padding: var(--gap);

  @media (--md) {
    padding: var(--gap-xl);
  }
}

.modal__title {
  color: var(--primary-color-dark-alt);
  font-size: calc(2.8rem + 0.15vw);
  margin-bottom: var(--gap);
}

/* Close Btn */

.modal-close {
  align-items: center;
  background-color: var(--tertiary-color);
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: var(--modal-close-size-sm);
  justify-content: center;
  position: absolute;
  right: var(--gap-xxxs);
  top: var(--gap-xxxs);
  transition: background-color var(--transition-dur);
  width: var(--modal-close-size-sm);
  z-index: 300;

  @media (--xl) {
    height: var(--modal-close-size);
    right: calc(var(--modal-close-size) / 2 * -1);
    top: calc(var(--modal-close-size) / 2 * -1);
    width: var(--modal-close-size);
  }

  &:hover {
    background-color: var(--primary-color);
  }

  svg {
    fill: #fff;
    height: 40%;
    transition: fill var(--transition-dur);
    width: 40%;
  }
}

/* Expand Modal Btn */

.expand-modal__btn {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;

  &:hover {
    .expand-modal__btn__text {
      color: var(--primary-color);
    }
  }
}

.expand-modal__btn__icon {
  align-items: center;
  display: flex;
  height: 1.6rem;
  justify-content: center;
  margin-right: var(--gap-xxs);
  width: 1.6rem;

  svg {
    fill: var(--tertiary-color);
    height: 100%;
    width: 100%;
  }
}

.expand-modal__btn__text {
  color: var(--tertiary-color);
  font-size: 1.5rem;
  font-weight: 600;
  text-transform: uppercase;
  transition: color var(--transition-dur);
}
