:root {
  --ratings-mark-size: 3.2rem;
}

.ratings {
  display: flex;
  flex-wrap: wrap;
}

.ratings-mark {
  align-items: center;
  background-color: var(--neutral-color);
  border-radius: 50%;
  display: flex;
  height: var(--ratings-mark-size);
  justify-content: center;
  width: var(--ratings-mark-size);

  &:not(:last-child) {
    margin-right: var(--gap-xxxs);
  }

  &--filled {
    background-color: var(--secondary-color);
  }

  svg {
    fill: #fff;
    height: 80%;
    width: 80%;
  }
}
