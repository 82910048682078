.site-cred {
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  display: flex;
  height: 6rem;
  justify-content: center;
  width: 6rem;

  svg {
    fill: var(--dark-color);
    height: 70%;
    width: 70%;
  }
}
