.contact-map {
  align-items: center;
  background-color: var(--primary-color-darker);
  background-image: url("../img/contact-map.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  min-height: 50rem;
  padding: var(--gap);

  @media (--md) {
    padding: var(--gap-xl);
  }
}

.contact-map__content {
  align-items: center;
  display: flex;
  justify-content: center;

  @media (--md) {
    margin-left: 22rem;
  }

  @media (--lg) {
    margin-left: 20rem;
  }
}

.contact-map__pin {
  background-image: url("../img/contact-pin.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: none;
  height: 12.5rem;
  margin-right: var(--gap);
  width: 10.5rem;

  @media (--md) {
    display: block;
  }
}

.contact-map__info {
  background-color: #fff;
  border-radius: var(--border-radius);
  box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.5);
  padding: var(--gap);
  position: relative;

  @media (--md) {
    min-width: 40rem;
  }

  &::before {
    border-bottom: 1.4rem solid transparent;
    border-right: 1.4rem solid #fff;
    border-top: 1.4rem solid transparent;
    content: "";
    display: none;
    left: -1.4rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    @media (--md) {
      display: block;
    }
  }
}

.contact-map__title {
  font-size: 2.4rem;
  margin-bottom: var(--gap-xxxs);
  text-align: center;
}

.contact-map__text {
  font-size: 1.5rem;
  margin-bottom: var(--gap-sm);
  text-align: center;
  text-transform: uppercase;
}
